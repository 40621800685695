// import local fonts
// @font-face {
//   font-family: "Monteserrat";
//   font-style: normal;
//   // font-weight: 100;
//   src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
// }
@font-face {
  font-family: "WorkSans";
  font-style: normal;
  src: url("../fonts/WorkSans-VariableFont_wght.ttf") format("truetype");
}

html,
body {
  margin: 0px;
  overflow-x: clip !important;
  scroll-behavior: smooth !important;
  font-family: "WorkSans";
  background: #f5f7fb;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  --moz-appearance: textfield;
}

.ptb-50 {
  padding: 50px 0px;
}

.ptb-25 {
  padding: 25px 0px;
}

.tdn {
  text-decoration: none !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}
