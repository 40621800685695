// IMPORT FONTS

// import google fonts
// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&display=swap');

// import local fonts
@font-face {
  font-family: "WorkSansBold";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/WorkSans-Bold.ttf") format("truetype");
}

// VARIABLE

//Color Variables
$pop-orange: #fc8750;
$line-grey: #aeadad;
$veri-peri: #676999;
$base-light: #f5f7fb;
$dark-purple: #1c1464;
$soft-orange: #feefe8;
$dark-orange: #fc5e2d;

// TEXT CCOLORS
.text-orange {
  color: $pop-orange !important;
}

.text-purple {
  color: $dark-purple !important;
}

// BACKGROUND COLORS

.bg-theme {
  background: $base-light !important;
}

// FONTS
.f-wsb {
  font-family: "WorkSansBold";
}

.tdn {
  text-decoration: none !important;
}

.center {
  height: 95vh !important;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  margin-top: 4rem;
  height: 5rem;
}

.header {
  height: auto;
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header-link {
  color: $dark-purple;
  position: relative;
  text-decoration: none;
  display: grid;
  font-size: 1rem;
  font-weight: 500;
  // place-items: center;
  cursor: pointer;
  // height: 50px;
}

.header-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background: linear-gradient(275deg, #fc8750 26.68%, #ff4b4b 73.58%);
  bottom: 0;
  left: 0;
  transform-origin: center;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.header-link:hover::before {
  transform-origin: center;
  transform: scaleX(1);
}

.sidebar {
  width: 250px;
  background-color: #333838;
  // background-image: linear-gradient(45deg, $pop-orange 100%, $dark-orange 0%);
  // background-image: linear-gradient(to bottom, #f7be72 0%, #fc8750 100%);
  background: gainsboro;

  height: 100vh;
  z-index: 7;
  transform: translateX(-100%); // new property - will move the element off the left hand side of the screen
  transition: transform 0.5s ease-in-out; // new property - will make the sidebar slide in in a similar manner to your animation
}

.sidebar-visible {
  transform: translateX(0); // new property - makes sidebar sit in its natural position (i.e. taking up the whole viewport)
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  // height: 40px;
  font-size: 14px;
  text-align: center;
  padding: 7px;
  background: white;
  border-top: 1px solid #e4e4e4;
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px solid $line-grey;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: $pop-orange;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-pill {
  color: white;
  background: $pop-orange;
  border-radius: 25px;
  // width: 150px;
  padding: 0px 30px;
  height: 50px;
  align-items: center;

  &:hover {
    color: white;
    background-color: $dark-orange;
  }

  &:focus-visible {
    color: white;
    border: 2px solid $line-grey;
    background-color: $dark-orange;
  }
}

.btn-link {
  color: $pop-orange;

  &:hover {
    color: $dark-orange;
  }
}

.hero-title {
  font-family: "WorkSansBold";
  font-size: 45px;
  // font-weight: 500;
  line-height: 50px;
  // text-transform: uppercase;
  margin: 4rem 0 0 0;
  color: $dark-purple;
}

@media (max-width: 576px) {
  .hero-title {
    margin: 2rem 0 0 0;
    font-size: 45px !important;
    line-height: 50px !important;
  }
}

.shiner {
  background: -webkit-linear-gradient(#f77103, #ffb963);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-sub {
  font-size: 16px;
  // font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.01em;
  margin: 1rem 0;
  color: #757589;
}

.input-search-group {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  text-align: center;
}

.input-search {
  flex: 1;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 25px 0 0 25px;
  outline: none;
  width: 500px;

  &:focus {
    border: 1px solid $pop-orange;
    box-shadow: 0 0 0 0.25rem rgba(253, 165, 13, 0.25);
    border-right: none;
  }
}

@media (max-width: 576px) {
  .input-search {
    width: 300px !important;
  }
}

.input-search-button {
  padding: 10px 15px;
  background-color: $pop-orange;
  color: white;
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  border-right: 2px solid #ccc;
  border-left: none;
  border-radius: 0 25px 25px 0;
  cursor: pointer;
}

.category-pill {
  margin: 5px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid $line-grey;
  background: white;
  width: max-content;
  border-radius: 4rem;

  &:hover {
    background: $dark-orange;
    color: white;
  }
}

.active-pill {
  background: $pop-orange;
  color: white;
}

.job-card {
  width: auto;
  height: 240px;
  padding: 20px 30px;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 2px 8px 0px #00000014;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

.company-logo {
  border-radius: 8px;
  overflow: hidden;
  min-width: 50px;
  min-height: 50px;
}

.role-title {
  font-size: 16px;
  font-weight: 500;
  color: $dark-purple;
}

.up-to-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Limits to 2 lines */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jc-sub-text {
  color: #807d7d;
  font-size: 14px;
  font-weight: 500;
}

.date-one {
  color: #838383;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
}

.btn-card {
  font-size: 14px;
  // font-weight: 500;
  background: $pop-orange;
  color: white;
  line-height: 15px;

  &:hover {
    background: $dark-orange;
    color: white;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

.job-detail-card {
  background: white;
  border-radius: 8px;
}

.line-one {
  border: 1px solid $base-light;
}

.jd-sub-title {
  font-family: "WorkSansBold";
}

.jd-desc-box {
  // height: 200px;
  // overflow: auto;
  padding-right: 5px;
  color: #565656;
  font-size: 14px;
}

.badge-blue {
  background: #eef5ff;
  padding: 5px 10px;
  border-radius: 4px;
  color: #5098fc;
  margin: 5px;
  font-size: 12px;
}

.badge-pink {
  background: #fff3fc;
  padding: 5px 10px;
  border-radius: 4px;
  color: #fc50d7;
  margin: 5px;
  font-size: 12px;
}

.badge-green {
  background: #ddfce0;
  padding: 5px 10px;
  border-radius: 4px;
  color: #12cb1b;
  margin: 5px;
  font-size: 12px;
}

.side-card-one {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
}

.sco-com-name {
  font-family: "WorkSansBold";
  font-size: 20px;
}

.sco-location {
  color: #807d7d;
  font-size: 14px;
}

.sco-desc {
  // height: 90px;
  // overflow-y: auto;
  padding-right: 5px;
  font-size: 14px;
}

.side-card-two {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  // height: 250px;
  // margin-top: 20px;
}

.sct-title {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
}

.sct-skills {
  height: 160px;
  overflow-y: auto;
}

.badge-skill {
  background: #f6f6f6;
  font-size: 14px;
  margin: 5px;
  padding: 8px 18px;
  border-radius: 25px;
  width: max-content;
}

.form-container {
  background: white;
  margin: 1rem 0;
  padding: 0.3rem;
  border-radius: 8px;
}

.form-label {
  text-align: start;
  font-size: 16px;
  font-weight: 500;
}

.label-one {
  text-align: start;
  font-size: 12px;
  font-weight: 500;
}

.input-one {
  margin-top: 4px;
  padding: 8px;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: 1px solid $line-grey;

  &:focus-visible {
    outline: $pop-orange auto 1px;
  }

  &::placeholder {
    font-size: 14px;
  }
}

.dropdown-toggle {
  background: transparent;
  color: black;
  margin-top: 7px;
  padding: 10px;
  width: 100%;
  height: 50px;
  border-radius: 12px;
  border: 1px solid $line-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    border: 1px solid $line-grey;
    background: $pop-orange;
  }

  &:active {
    color: black !important;
    background-color: $pop-orange !important;
    border-color: $line-grey !important;
  }

  &:focus-visible {
    outline: #93cca0 auto 1px;
    border: 1px solid $line-grey;
    background-color: $pop-orange;
  }

  &::after {
    margin-right: 10px !important;
  }
}

.dropdown-item:active {
  // color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: $pop-orange;
  z-index: 10 !important;
}

.btn.show {
  background-color: $pop-orange;
  border: 1px solid $line-grey;
}

.btn:disabled {
  background-color: $base-light;
  border: 1px solid $line-grey;
}

.form-check-input {
  border: 1px solid $line-grey;
  margin-top: 0px !important;

  &:focus {
    border: 1px solid $line-grey;
    box-shadow: 0 0 0 0.25rem rgb(250 100 14 / 30%);
  }

  &:checked {
    border: 1px solid $line-grey;
    background-color: $pop-orange;
    box-shadow: 0 0 0 0.25rem rgb(250 100 14 / 30%);
  }
}

.upload-badge {
  font-size: 14px;
  background: $base-light;
  padding: 4px 7px;
  border-radius: 4px;
  color: $pop-orange;
}

.btn-icon {
  background-color: transparent !important;
  border: 1px solid #fdaaaa;
  height: 50px;
  width: 50px;
  line-height: 20px;
  color: #fc7e7e;

  &:hover {
    background-color: #fc7e7e !important;
    color: white;
  }
}

.accordion-button {
  background-color: $soft-orange !important;

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(253, 149, 13, 0.25);
  }
}

.vr-ornage {
  height: 50px;
  width: 1px;
  background: $pop-orange;
}

.vr-success {
  height: 50px;
  width: 1px;
  background: #198754;
}

.vr-danger {
  height: 50px;
  width: 1px;
  background: #ff7171;
}

.btn-plain-icon {
  cursor: pointer;
  color: $pop-orange;

  &:hover {
    color: $dark-orange;
  }
}

.bc-link {
  cursor: pointer;

  &:hover {
    color: $dark-orange;
  }
}

.w-sm-full {
  @media (max-width: 576px) {
    min-width: 100%;
  }
}

.w-md-full {
  @media (max-width: 768px) {
    min-width: 100%;
  }
}

// new modifications in css
.job-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 8px;
  font-size: 12px;
  color: #6b7280;
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  flex-basis: calc(
    50% - 8px
  ); /* Ensures two items per row on smaller screens */
}

@media (min-width: 768px) {
  .meta-item {
    flex-basis: auto; /* Allows auto-width on larger screens */
  }
}

/* Background colors */
.location {
  background: #dbebff;
  color: #2563eb;
}

.experience {
  background: #d1fadf;
  color: #047857;
}

.job-type {
  background: #ede9fe;
  color: #6d28d9;
}

.work-type {
  background: #fad4d4;
  color: #a80303;
}

.mr-5 {
  margin-right: 8px;
  margin-top: 2px;
}

/*right sidebar job page scss*/
.profile-score {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 8px 0px #00000014;
  transition: box-shadow 0.3s ease-in-out;
  border: 1px solid #e5e7eb;

  .profile-banner {
    background: linear-gradient(to right, #7b2cbf, #4c1d95);
    color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    text-align: center;

    h3 {
      font-weight: 600;
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 0.875rem;
    }

    button {
      margin-top: 1rem;
      background: white;
      color: #4c1d95;
      padding: 0.5rem 1rem;
      border-radius: 0.375rem;
      font-weight: 500;
      // transition: background 0.2s;

      &:hover {
        box-shadow: 0px 2px 8px 0px #00000014;
        transition: box-shadow 0.3s ease-in-out;
      }
    }
  }
}
.job-recom {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  cursor: pointer;
  height: auto;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 2px 8px 0px #00000014;
  }
}

.company-hiring {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 8px 0px #00000014;
  transition: box-shadow 0.3s ease-in-out;
  border: 1px solid #e5e7eb;

  h3 {
    font-weight: 600;
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }

  .company-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .company-card {
      background: linear-gradient(to right, #ebf2ff, #e0e7ff);
      padding: 1rem;
      border-radius: 0.75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: transform 0.2s;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }

      .company-info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;

        img {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          box-shadow: 0px 2px 8px 0px #00000014;
          transition: box-shadow 0.3s ease-in-out;
        }

        h4 {
          font-weight: 500;
          color: #1f2937;
        }

        p {
          font-size: 0.875rem;
          color: #6b7280;
        }
      }

      span {
        font-size: 0.875rem;
        font-weight: 600;
        background: white;
        padding: 0.25rem 0.75rem;
        border-radius: 9999px;
        box-shadow: 0px 2px 8px 0px #00000014;
        transition: box-shadow 0.3s ease-in-out;
      }
    }

    .company-alt {
      background: linear-gradient(to right, #f3e8ff, #fce7f3);
    }
  }

  .popular-roles {
    margin-top: 1.5rem;

    h3 {
      font-weight: 600;
      font-size: 1.125rem;
      margin-bottom: 1rem;
    }

    .roles-grid {
      display: flex;
      flex-direction: column;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.75rem;

      .role-card {
        width: 100%;
        background: white;
        padding: 0.75rem;
        border-radius: 0.5rem;
        border: 1px solid #e5e7eb;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          border-color: #6366f1;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }

        div {
          font-weight: 500;
          color: #1f2937;
        }

        span {
          font-size: 0.875rem;
          color: #6366f1;
        }
      }
    }
  }
}

/*Job Filter*/
.container {
  padding: 0.2rem;
}
.h-auto {
  height: 300px;
}
.form-group {
  margin-bottom: 1rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #6b7280;
}

.form-control,
.form-select,
.form-check-input {
  width: 100%;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  padding: 0.5rem;
  color: rgb(93, 93, 93) !important;
}

.btn {
  background-color: $pop-orange;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $dark-orange;
  }
}

.gradient-bg {
  background: linear-gradient(to right, #7b2cbf, #4c1d95);
}

.flex {
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  gap: 20px;
}

.share {
  background-color: $pop-orange;
  border: none;
  color: white;
  &:hover {
    background-color: $dark-orange;
  }
}

.custom-width {
  width: 100%; /* Adjust this value as needed */
}

.clear {
  // padding: 0.5rem 0.5rem;
  font-size: large;
  line-height: 1.25rem;
  font-weight: normal;
  cursor: pointer;
  color: $pop-orange;

  &:hover {
    color: $dark-orange;
  }
}

.icon {
  border: none;
  color: $pop-orange;
  border-radius: 5px;
  &:hover {
    color: $dark-orange;
  }
}

.hero-section {
  width: 100%;
  height: auto;
  border-radius: 8px;
  cursor: pointer;
  background: linear-gradient(to right, #7b2cbf, #4c1d95);
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition */
}

.input-search-filter {
  width: 200px;
  height: auto;
  flex: 1;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 25px 0 0 25px;
  outline: none;
  width: 500px;

  &:focus {
    border: 1px solid $pop-orange;
    box-shadow: 0 0 0 0.25rem rgba(253, 165, 13, 0.25);
    border-right: none;
  }
}

.job-card-new {
  height: auto;
  padding: 10px 20px;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 2px 8px 0px #00000014;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

.skill-card {
  height: auto;
  padding: 10px 20px;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 2px 8px 0px #00000014;
  cursor: pointer;
}

.profile-card {
  height: 11rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 2px 8px 0px #00000014;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

.company-card {
  padding: 20px 30px;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 2px 8px 0px #00000014;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;

  // &:hover {
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // }
}

.companyPage-card {
  height: auto;
  width: auto;
  padding: 5px 10px;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 2px 8px 0px #00000014;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}

.companyPage-logo {
  min-width: 50px;
  min-height: 30px;
}

.title {
  font-size: 0.8rem;
  font-weight: 500;
  color: $dark-purple;
}

.customer-title {
  font-size: 18px;
  font-weight: 500;
  color: $dark-purple;
}

.job-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 6px 0;
}

.custom-checkbox {
  width: 1rem;
  height: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.custom-checkbox:focus {
  border-color: #fa640e;
  box-shadow: 0 0 4px rgba(250, 100, 14, 0.5);
}

.custom-checkbox:checked {
  background-color: #fa640e;
  border-color: #fa640e;
}

.job-label {
  font-size: 14px;
  color: #333;
}

.custom-height {
  height: 100vh;
}

@media (max-width: 767px) and (max-height: 599px) {
  .custom-height {
    height: 13rem !important;
  }
}

.No-data {
  padding: 10px 25px;
  border-radius: 8px;
  background: white;
  border: 1px solid #d8dbdd;
}

.checkbox-title {
  color: rgb(51, 50, 50);
}

//form slider css

/* Full-Page Container */
.full-page-container {
  width: 100%;
  padding: 5px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.overlay.open {
  opacity: 1;
  pointer-events: auto;
}

/* Slider Container */
.slider-container {
  position: fixed;
  top: 0;
  right: -50%; /* Initially hidden */
  width: 50%;
  height: 100%;
  background: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  transition: right 1s ease-in-out;
  z-index: 1000;
  overflow-y: auto; /* Enable scrolling if content overflows */
}

.slider-container.open {
  right: 0; /* Slide in */
}

/* Slider Content */
.slider-content {
  padding: 5px;
}

/* Close Button */
.slider-content .btn-secondary {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (max-width: 1024px) {
  .slider-container {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .slider-container {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .slider-container {
    width: 90%;
  }
}
.custom-tooltip {
  
  .tooltip-inner {
    background-color: black !important;
    color: white !important;
    text-align: center;
    word-wrap: break-word;
    max-width: 200px; /* Adjust as needed */
  }

  .bs-tooltip-top .tooltip-arrow::before {
    border-top-color: black !important;
  }
}

.max-height-50vh {
  max-height: 50vh;
  overflow: auto; /* Optional: Adds scroll if content overflows */
}

.custom-bg {
  background-color: $pop-orange !important;
  color: white;
  transition: background-color 0.3s ease-in-out;
}

.custom-bg:hover {
  background-color: $dark-orange !important;
}

.hover-effect:hover {
  color: #fc5e2d !important;
}

.custom-modal-height .modal-content {
  min-height: 200px;
}